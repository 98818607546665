
module.exports = `
     <text transform="matrix(1, 0, 0, 1, 104.571, 235)" x="40" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{1}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 199.658, 235)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{2}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 294.232, 235)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{3}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 104.571, 325.308)"   x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{4}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 199.658, 325.308)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{5}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 294.232, 325.308)" x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{6}</tspan>
    </text>
     <text transform="matrix(1, 0, 0, 1, 104.571, 418)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{7}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 199.658, 418)" x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{8}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 294.232, 418)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{9}</tspan>
    </text>
      <text transform="matrix(1, 0, 0, 1, 394.319, 235)" x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{10}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 394.319, 325.308)" x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{11}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 394.319, 418)"  x="45" y="15.5" text-anchor="end">
      <tspan font-family="Karla-Regular" font-size="48" fill="#000000">{12}</tspan>
    </text>
    <text transform="matrix(1, 0, 0, 1, 270, 113.772)"  x="0" y="15.5" text-anchor="middle">
      <tspan font-family="SourceSerifPro-Regular" font-size="40" kerning="2" fill="#E4DFD1">{0}</tspan>
    </text>
`