
module.exports.questions = [
  "When someone else is feeling excited, I tend to get excited too.",
  "Other people’s misfortunes do not bother me much.",
  "It upsets me to see someone being treated disrespectfully.",
  "I am happy when someone close to me is happy.",
  "I enjoy making other people feel better.",
  "I have tender, concerned feelings for people less fortunate than me.",
  "When a friend starts to talk about his/her problems, I try to steer the conversation towards something else.",
  "I can tell when others are sad even when they do not say anything.",
  "I find that I am “in tune” with other people’s moods.",
  "I feel sympathy for people who cause their own serious illnesses.",
  "I become irritated when someone cries.",
  "I am interested in how other people feel.",
  "I get a strong urge to help when I see someone who is upset.",
  "When I see someone being treated unfairly, I feel badly for them.",
  "I find it silly for people to cry out of happiness.",
  "When I see someone being taken advantage of, I feel kind of protective towards him/her."
];

var scoring = [
  [0, 1, 2, 4, 3],
  [0, 3, 4, 2, 0],
  [0, 1, 2, 4, 3],
  [0, 1, 2, 4, 3],
  [0, 1, 2, 4, 3],
  [0, 1, 2, 4, 3],
  [3, 4, 2, 1, 0],
  [0, 1, 2, 4, 4],
  [0, 1, 2, 4, 4],
  [0, 1, 3, 4, 0],
  [2, 4, 3, 0, 0],
  [0, 0, 3, 4, 3],
  [0, 0, 2, 4, 3],
  [0, 1, 2, 4, 3],
  [4, 4, 2, 1, 0],
  [0, 1, 2, 3, 4]
];

module.exports.confirmation = [
  "Never",
  "Rarely",
  "Sometimes",
  "Often",
  "Always"
];

module.exports.analyze = function (answers) {

  var SHEQ = 0;

  answers.forEach(function (answer, question) {
    SHEQ += scoring[question][answer.value]
  })

  var blob = { SHEQ: SHEQ }

  return blob;

}