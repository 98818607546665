exports.ImageCard = function (url) {
  return {
    type: "Standard",
    "attachments": [
      {
        "type": "image",
        "image": url
      }
    ]
  }
}

exports.YesNoCard = function (question) {
  return {
    type: "Standard",
    text: question,
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "no",
            "text": "No",
            "type": "button",
            "value": "NoIntent"
          },
          {
            "name": "yes",
            "text": "Yes",
            "type": "button",
            "value": "YesIntent"
          }
        ]
      }
    ]
  }
}

exports.YesCard = function (question) {
  return {
    type: "Standard",
    text: question,
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "yes",
            "text": "Yes",
            "type": "button",
            "value": "YesIntent"
          }
        ]
      }
    ]
  }
}

exports.JobTypeCard = function (descriptions) {
  return {
    type: "Standard",
    text: descriptions,
    title: "Position",
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "coachI",
            "text": "COACH I",
            "type": "button",
            "value": "CoachIIntent"
          },
          {
            "name": "coachII",
            "text": "COACH II",
            "type": "button",
            "value": "CoachIIIntent"
          },
          {
            "name": "clinicianI",
            "text": "CLINICIAN I",
            "type": "button",
            "value": "ClinicianIIntent"
          },
          {
            "name": "clinicianII",
            "text": "CLINICIAN II",
            "type": "button",
            "value": "ClinicianIIIntent"
          }
        ]
      }
    ]
  }
}


exports.FileUploaderCard = function (title, options) {
  return {
    type: "Standard",
    text: title,
    "attachments": [
      {
        "type": "uploader",
        "title": title,
        "footer": options.footer,
        "accept": options.accept,
        "nopreview": options.nopreview || false,
        "skip": options.skip
      }
    ]
  }
}

exports.TextCard = function (title, image, text, footer) {
  return {
    type: "Standard",
    "attachments": [
      {
        "type": "text",
        "title": title,
        "footer": footer,
        "text": text,
        "image": image
      }
    ]
  }
}

exports.FrequencyCardF6 = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: "Question #" + (questionNumber + 1),
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "usually",
            "text": "Usually",
            "type": "button",
            "value": "UsuallyIntent"
          },
          {
            "name": "often",
            "text": "Often",
            "type": "button",
            "value": "OftenIntent"
          },
          {
            "name": "sometimes",
            "text": "Sometimes",
            "type": "button",
            "value": "SometimesIntent"
          },
          {
            "name": "occasionally",
            "text": "Occasionally",
            "type": "button",
            "value": "OccasionallyIntent"
          }, {
            "name": "rarely",
            "text": "Rarely",
            "type": "button",
            "value": "RarelyIntent"
          }, {
            "name": "never",
            "text": "Never",
            "type": "button",
            "value": "NeverIntent"
          }
        ]
      }
    ]
  }
}

exports.FrequencyCardPeople = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: "Question #" + (questionNumber + 1),
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "mostpeople",
            "text": "Most People",
            "type": "button",
            "value": "MostPeopleIntent"
          },
          {
            "name": "manypeople",
            "text": "Many People",
            "type": "button",
            "value": "ManyPeopleIntent"
          },
          {
            "name": "somepeople",
            "text": "Some People",
            "type": "button",
            "value": "SomePeopleIntent"
          },
          {
            "name": "afewpeople",
            "text": "A Few People",
            "type": "button",
            "value": "AFewPeopleIntent"
          }, {
            "name": "oneortwopeople",
            "text": "One or Two People",
            "type": "button",
            "value": "OneOrTwoPeopleIntent"
          }, {
            "name": "nobody",
            "text": "Nobody",
            "type": "button",
            "value": "NobodyIntent"
          }
        ]
      }
    ]
  }
}

exports.decodeIntent = {
  "YesIntent": "Yes",
  "NoIntent": "No",
  "CoachIIntent": "Coach I",
  "CoachIIIntent": "Coach II",
  "ClinicianIIntent": "Clinician I",
  "ClinicianIIIntent": "Clinician II",
  "ReadyIntent": "Ready",
  "StartSurveyIntent": "Start",
  "DontKnowIntent": "Don't Know",
  "StartOverIntent": "Start Over",
  "RepeatIntent": "Repeat",
  "StopIntent": "Stop",
  "UsuallyIntent": "usually",
  "OftenIntent": "often",
  "SometimesIntent": "sometimes",
  "OccasionallyIntent": "occasionally",
  "RarelyIntent": "rarely",
  "NeverIntent": "never",
  "MostPeopleIntent": "most people",
  "ManyPeopleIntent": "many people",
  "SomePeopleIntent": "sometimes",
  "AFewPeopleIntent": "a few people",
  "OneOrTwoPeopleIntent": "one or two people",
  "NobodyIntent": "nobody"
}