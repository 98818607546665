
const img = require('./report.svg.js');
const imgtext = require('./report.text.svg.js');

module.exports.questions = [
 /* 1 */ 'I try to be with people.',
    'I let other people decide what to do.',
    'I join social groups.',
    'I try to have close relationships with people.',
    'I tend to join social organizations when I have an opportunity.',
    'I let other people strongly influence my actions.',
    'I try to be included in informal social activities.',
    'I try to have close, personal relationships with people.',
    'I try to include other people in my plans.',
    'I let other people control my actions.',
    'I try to have people around me.',
    'I try to get close and personal with people.',
    'When people are doing things together I tend to join in.',
    'I am easily led by people.',
    'I try to avoid being alone.',
    'I try to participate in group activities.',
 /* 17 */  'I try to be friendly to people.',
    'I let other people decide what to do.',
    'My personal relations with people are cool and distant.',
    'I let other people take charge of things.',
    'I try to have close relationships with people.',
    'I let other people strongly influence my actions.',
    'I try to get close and personal with people.',
    'I let other people control my actions.',
    'I act cool and distant with people.',
    'I am easily led by people.',
    'I try to have close, personal relationships with people.',
    'I like people to invite me to things.',
    'I like people to act close and personal with me.',
    'I try to influence strongly other people\'s actions.',
    'I like people to invite me to join in their activities.',
    'I like people to act close toward me.',
    'I try to take charge of things when I am with people.',
    'I like people to include me in their activities.',
    'I like people to act cool and distant toward me.',
    'I like to have other people do things the way I want them done.',
    'I like people to ask me to participate in their discussions.',
    'I like people to act friendly toward me.',
    'I like people to invite me to participate in activities.',
  /* 40 */ 'I like people to act distant toward me.',
    'I try to be the dominant person when I am with people.',
    'I like people to invite me to things.',
    'I like people to act close toward me.',
    'I like to have other people do things I want done.',
    'I like people to invite me to join their activities.',
    'I like people to act cool and distant toward me.',
    'I try to influence strongly other people\'s actions.',
    'I like people to include me in their activities.',
    'I like people to act close and personal with me.',
    'I try to take charge of things when I\'m with people.',
    'I like people to invite me to participate in activities.',
    'I like people to act distant toward me.',
    'I try to have other people do things the way I want them done.',
    'I take charge of things when I\'m with people.'
];

var scoring = {
    'expressed_inclusion': [
        [1, 1, 3],
        [3, 1, 4],
        [5, 1, 4],
        [7, 1, 3],
        [9, 1, 2],
        [11, 1, 2],
        [13, 1, 2],
        [15, 1, 1],
        [16, 1, 1]],

    'wanted_inclusion': [
        [28, 1, 2],
        [31, 1, 2],
        [34, 1, 2],
        [37, 1, 1],
        [39, 1, 1],
        [42, 1, 2],
        [45, 1, 2],
        [48, 1, 2],
        [51, 1, 2]],

    'expressed_control': [
        [30, 1, 3],
        [33, 1, 3],
        [36, 1, 2],
        [41, 1, 4],
        [44, 1, 3],
        [47, 1, 3],
        [50, 1, 2],
        [53, 1, 2],
        [54, 1, 2]],

    'wanted_control': [
        [2, 1, 4],
        [6, 1, 4],
        [10, 1, 3],
        [14, 1, 3],
        [18, 1, 3],
        [20, 1, 3],
        [22, 1, 4],
        [24, 1, 3],
        [26, 1, 3]],

    'expressed_affection': [
        [4, 1, 2],
        [8, 1, 2],
        [12, 1, 1],
        [17, 1, 2],
        [19, 4, 6],
        [21, 1, 2],
        [23, 1, 2],
        [25, 4, 6],
        [27, 1, 2]],

    'wanted_affection': [
        [29, 1, 2],
        [32, 1, 2],
        [35, 5, 6],
        [38, 1, 2],
        [40, 5, 6],
        [43, 1, 1],
        [46, 5, 6],
        [49, 1, 2],
        [52, 5, 6]]
}

module.exports.analyze = function(answers) {

    var results = {};

    for (var dimension in scoring) {
        if (scoring.hasOwnProperty(dimension)) {
            results[dimension] = 0;
            var ranges = scoring[dimension];

            ranges.forEach(function(range) {
                var q = range[0] - 1;
                var lowerbound = range[1];
                var upperbound = range[2];

                if (answers[q] && (answers[q].value >= lowerbound) && (answers[q].value <= upperbound))
                    results[dimension]++;

            });

        }
    }

    results["sum_inclusion"] = results.expressed_inclusion + results.wanted_inclusion;
    results["sum_control"] = results.expressed_control + results.wanted_control;
    results["sum_affection"] = results.expressed_affection + results.wanted_affection;
    results["total_expressed"] = results.expressed_inclusion + results.expressed_control + results.expressed_affection;
    results["total_wanted"] = results.wanted_inclusion + results.wanted_control + results.wanted_affection;
    results["total_need"] = results["total_expressed"] + results["total_wanted"];

    var sorted = [
        { name: 'expressed_inclusion', value: results.expressed_inclusion },
        { name: 'expressed_control', value: results.expressed_control },
        { name: 'expressed_affection', value: results.expressed_affection },
        { name: 'wanted_inclusion', value: results.wanted_inclusion },
        { name: 'wanted_control', value: results.wanted_control },
        { name: 'wanted_affection', value: results.wanted_affection },
    ];

    sorted.sort(function(a, b) {
        if (a.value > b.value) { return -1; }
        if (a.value < b.value) { return 1; }
        return 0;
    });

    results["basic_highest"] = sorted[0].name;
    results["basic_lowest"] = sorted[5].name;


    sorted = [
        { name: 'expressed_inclusion', value: results.expressed_inclusion },
        { name: 'expressed_control', value: results.expressed_control },
        { name: 'expressed_affection', value: results.expressed_affection },
    ];

    sorted.sort(function(a, b) {
        if (a.value > b.value) { return -1; }
        if (a.value < b.value) { return 1; }
        return 0;
    });

    results["expressed_highest"] = sorted[0].name;

    sorted = [
        { name: 'inclusion', value: results.sum_inclusion },
        { name: 'control', value: results.sum_control },
        { name: 'affection', value: results.sum_affection },
    ];

    sorted.sort(function(a, b) {
        if (a.value > b.value) { return -1; }
        if (a.value < b.value) { return 1; }
        return 0;
    });

    results["sum_highest"] = sorted[0].name;
    results["sum_highest_value"] = sorted[0].value;
    results.summary = {};


    for (var dimension in thresholds) {
        if (thresholds.hasOwnProperty(dimension)) {
            var levels = thresholds[dimension];
            levels.forEach(function(level, i) {
                if (results[dimension] >= level)
                    results.summary[dimension] = {
                        "label": decodeThresholds[i],
                        "index": i,
                        "value": results[dimension]
                    }
            })
        }
    };

    return results;
}

var thresholds = {
    "expressed_inclusion": [0, 3, 7],
    "expressed_control": [0, 3, 7],
    "expressed_affection": [0, 3, 7],
    "wanted_inclusion": [0, 3, 7],
    "wanted_control": [0, 3, 7],
    "wanted_affection": [0, 3, 7],
    "sum_inclusion": [0, 6, 13],
    "sum_control": [0, 6, 13],
    "sum_affection": [0, 6, 13],
    "total_expressed": [0, 8, 20],
    "total_wanted": [0, 8, 20],
    "total_need": [0, 16, 27, 39],
}

var dimensions_basic = [
    "expressed_inclusion",
    "expressed_control",
    "expressed_affection",
    "wanted_inclusion",
    "wanted_control",
    "wanted_affection"
]

var dimensions_sum = [
    "sum_inclusion",
    "sum_control",
    "sum_affection"
]

var dimensions_totals = [
    "total_expressed",
    "total_wanted",
    "total_need"
]

var decodeThresholds = ["low", "medium", "high", "very high"];

var interpretation = {
    "total": [
        "Low—and can be important because you may tend to avoid this area",
        "Medium—and can be moderately or sporadically important to you; or more important concerning some people and less important concerning others",
        "High—and can be important to you because this area tends to be a priority for you"
    ],
    "sum_inclusion": [
        "You generally have a low preference for being with others, no matter who initiates it.",
        "You usually prefer a balance between time alone and time with others, no matter who initiates it.",
        "You usually have a high preference for being involved in social situations, no matter who initiates it."],
    "sum_control": [
        "You usually prefer less-structured situations and have a laid-back attitude toward authority, generally preferring not to give or receive orders.",
        "You generally like a moderate amount of structure and clarity around authority in order to accomplish tasks.",
        "You generally prefer a structured situation where there are clear lines of authority and responsibility in order to get things done."],
    "sum_affection": [
        "You generally like to keep things impersonal and prefer more formal and businesslike relationships.",
        "You usually prefer a realistic amount of warmth and closeness in one-to-one relationships, no matter who initiates it.",
        "You generally like a lot of warmth and closeness in your one-to-one relationships."],
    "total_expressed": [
        "You usually do not initiate activities with others.",
        "Sometimes you initiate activities with others; sometimes you don’t.",
        "You usually initiate activities with others."],
    "total_wanted": [
        "You usually do not want others to initiate activities.",
        "Sometimes you want others to initiate activities with you; sometimes you don’t.",
        "You usually like for others to initiate activities with you."],
    "comparison_wanted_expressed": [
        "You probably like taking the initiative more than having others do so.",
        "You probably prefer to take the initiative part of the time, but at other score are equal times you prefer that someone else does so. You may also adopt a waitand-see attitude toward others; e.g., What do they want? What seems appropriate at the time? What do you feel like doing at the moment?.",
        "You probably prefer that others take the initiative more than doing so yourself."],
    "total_need": [
        "Interaction with others in all areas of Inclusion, Control, and Affection is not likely to be a strongly felt need. You may prefer to concentrate on more impersonal and objective concerns than on relationships with people. Your personal style may be rather cool, and you may have a strong preference for your own company, for making decisions independently, and for being close to only a few people you have known for a long time.",
        "Interaction with others in all areas of Inclusion, Control, and Affection may appeal to you on a selective basis. You are likely to be choosy about how, when, and where you associate with others—and to be cautious about how you use or share authority. Some close relationships are probably important to you, but there are likely to be times when you prefer to concentrate on the more impersonal demands of the task instead of the more personal ones.",
        "You generally find that interacting with other people in all areas of Inclusion, Control, and Affection is a source of satisfaction and that your interpersonal relationships help you attain the goals you want to reach. You may consult others without actually handing over authority to them. You are likely to enjoy a fair amount of teamwork and to value forming warm one-to-one relationships. You probably find that people’s company becomes overwhelming occasionally and that you then need to get away for some time alone.",
        "You probably enjoy engaging frequently with others in all areas of Inclusion, Control, and Affection. You are very likely to actively seek out, work on, and enjoy your interpersonal relationships. You tend to value very warm and friendly one-to-one relationships. You may prefer to share decision making and generally like involving others in what you do. Being without other people’s company may make you feel uncomfortable."
    ],
    "basic": ["you probably tend to show these behaviors in very few situations",
        "you probably tend to show these behaviors in selected situations",
        "you probably tend to show these behaviors very often"
    ]
}

var about = {
    "general": "The Sync Health Labs Interpersonal Preference (SHLIP) assessment instrument is based on research in the late 1950s by William Schutz, PhD. Schutz developed this theory to aid in the understanding and predicting of how high-performance military teams would work together.    It is somethimes known as the FIRO or FIRO-B assessment and is one of the most widely used leadership assessments ever used. We can use it today at Synchronous Health to understand how our own teams work together.",
    "basic": "The three interpersonal preferences (Inclusion, Control, and Affection) combine with the two dimensions (Expressed and Wanted) to form the six-cell model.  Examining each score separately gives you important information about yourself. Notice what each one indicates. The way the scores combine also yields meaningful insights into your personality.",
    "basic_summary": "The three interpersonal preferences (Inclusion, Control, and Affection) combine with the two dimensions (Expressed and Wanted) to form the six-cell model.  Examining each score separately gives you important information about yourself. Notice what each one indicates. Then look for your highest and lowest scores in these six boxes. These scores probably represent your most compelling interpersonal preferences. The way the scores combine also yields meaningful insights into your personality.",
    "sum_inclusion": "The sum total scores show the relative strength of your preferences in the areas of Inclusion, Control, and Affection.  It is usually meaningful to look at which of the three areas has the highest sum score. That area may be the most important interpersonal preference for you, therefore predominating your behavior and having the greatest impact in your relationships. Similarly, the area that has the lowest sum score may be either the one you try to avoid or the one that reflects situations from which you try to escape.",
    "total_expressed": "Your Total Expressed score shows how much you usually like to initiate action in relating to others, while your Total Wanted score shows how much you prefer that others take the initiative. Comparing your total expressed score to your total wanted score can show how you generally satisfy your interpersonal preferences—the blend you prefer in taking initiative or having others do so",
    "total_need": "The Total Interpersonal Preference score simply summarizes how strong your preference is for general interpersonal contact and interaction.",
    "inclusion": "The preference for Inclusion relates to forming new relations and associating with others; it determines the extent of contact and prominence that a person seeks. Descriptors include: belonging, recognition, involvement, distinction and participation.",
    "control": "The preference for Control relates to decision making, influence, and persuasion between people; it determines the extent of power or dominance that a person seeks. Descriptors include: power, responsibility, authority, consistency and influence.",
    "affection": "The preference for Affection relates to emotional ties and warm connections between people; it determines the extent of closeness that a person seeks. Descriptors include: personal ties, support, consensus, openness, and sensitivity.",
    "highest_expressed": "Because your highest Expressed preference represents the social arena where you feel more comfortable taking action, this result predicts which “face” you show first to a group. This preference will also probably shape the foundation of your leadership style.",
    "expressed_inclusion": "The extent to which you make an effort to include others in your activities, to join and belong to groups, and to be with people",
    "expressed_control": "The extent to which you make an effort to control and influence others or situations, to organize and direct others, and to assume responsibility",
    "expressed_affection": "The extent to which you try to get close to people and to engage them on a personal level; your degree of comfort in being open with and supportive of others",
    "wanted_inclusion": "The extent to which you want others to include you in their activities and to invite you to join or belong to groups; the extent to which you want to be noticed",
    "wanted_control": "The extent to which you are comfortable working in welldefined situations with clear expectations and instructions",
    "wanted_affection": "The extent to which you want others to act warmly toward you and to take a personal interest in you; the extent to which you want others to share things with you and to encourage you",
    "mismatch_low_wanted": {
        "inclusion": "You may be tempted to skip meetings, tune in and out, sit far away, position body away from group, work on other business, keep head down",
        "control": "You may move quickly to a firm position, be tempted to show up late/leave early, avoid assignments, not respond to emails, find a way out of rules",
        "affection": "You may avoid chit-chat, skip social events, be tempted to take on contrary positions, remove yourself by taking detailed notes"
    },
    "mismatch_high_wanted": {
        "inclusion": "You may share more information, ask for time to share your thoughts, request to be caught up, encourage everyone to express their opinions, offer a proposal, arrive early and sit centrally",
        "control": "You may ask many questions, point out options, express lack of confidence in team, identify obstacles, share your progress, express concerns",
        "affection": "You may offer to help people, encourage tough feedback, go above and beyond, make personal sacrifices, schedule lunches"
    }
}

var decode = {
    "sum_inclusion": "Inclusion",
    "sum_control": "Control",
    "sum_affection": "Affection",
    "total_expressed": "Expressed",
    "total_wanted": "Wanted",
    "total_need": "Total Interpersonal Preference",
    "expressed_inclusion": "Expressed Inclusion",
    "expressed_control": "Expressed Control",
    "expressed_affection": "Expressed Affection",
    "wanted_inclusion": "Wanted Inclusion",
    "wanted_control": "Wanted Control",
    "wanted_affection": "Wanted Affection",
    "inclusion": "Inclusion",
    "control": "Control",
    "affection": "Affection"
}

var decodeExpressed = {
    "expressed_inclusion": "Inclusion",
    "expressed_control": "Control",
    "expressed_affection": "Affection",
}

var questions = ["What do you think your team needs more of (inclusion, control, affection)? What’s your evidence?",
    "What one thing could you do more of in your team?", "What one thing could you do less of in your team?"]

module.exports.svg = function(results, firstname) {

    var data = 'data:image/svg+xml,' + img;
    data = data.replace("{text}", imgtext);
    data = data.replace("{0}", firstname);
    data = data.replace("{1}", results.expressed_inclusion);
    data = data.replace("{4}", results.wanted_inclusion);
    data = data.replace("{2}", results.expressed_control);
    data = data.replace("{5}", results.wanted_control);
    data = data.replace("{3}", results.expressed_affection);
    data = data.replace("{6}", results.wanted_affection);
    data = data.replace("{7}", results.sum_inclusion);
    data = data.replace("{8}", results.sum_control);
    data = data.replace("{9}", results.sum_affection);
    data = data.replace("{10}", results.total_expressed);
    data = data.replace("{11}", results.total_wanted);
    data = data.replace("{12}", results.total_need);

    return data;
}

module.exports.svgBasic = function(results, firstname) {

    var data = 'data:image/svg+xml,' + img;
    data = data.replace("{text}", imgtext);
    data = data.replace("{0}", firstname);
    data = data.replace("{1}", results.expressed_inclusion);
    data = data.replace("{4}", results.wanted_inclusion);
    data = data.replace("{2}", results.expressed_control);
    data = data.replace("{5}", results.wanted_control);
    data = data.replace("{3}", results.expressed_affection);
    data = data.replace("{6}", results.wanted_affection);
    data = data.replace("{7}", "");
    data = data.replace("{8}", "");
    data = data.replace("{9}", "");
    data = data.replace("{10}", "");
    data = data.replace("{11}", "");
    data = data.replace("{12}", "");

    return data;
}

module.exports.svgSums = function(results, firstname) {

    var data = 'data:image/svg+xml,' + img;
    data = data.replace("{text}", imgtext);
    data = data.replace("{0}", firstname);
    data = data.replace("{1}", "");
    data = data.replace("{4}", "");
    data = data.replace("{2}", "");
    data = data.replace("{5}", "");
    data = data.replace("{3}", "");
    data = data.replace("{6}", "");
    data = data.replace("{7}", results.sum_inclusion);
    data = data.replace("{8}", results.sum_control);
    data = data.replace("{9}", results.sum_affection);
    data = data.replace("{10}",  "");
    data = data.replace("{11}", "");
    data = data.replace("{12}",  "");

    return data;
}

module.exports.svgTotals = function(results, firstname) {

    var data = 'data:image/svg+xml,' + img;
    data = data.replace("{text}", imgtext);
    data = data.replace("{0}", firstname);
    data = data.replace("{1}", "");
    data = data.replace("{4}", "");
    data = data.replace("{2}", "");
    data = data.replace("{5}", "");
    data = data.replace("{3}", "");
    data = data.replace("{6}", "");
    data = data.replace("{7}",  "");
    data = data.replace("{8}",  "");
    data = data.replace("{9}",  "");
    data = data.replace("{10}", results.total_expressed);
    data = data.replace("{11}", results.total_wanted);
    data = data.replace("{12}", results.total_need);

    return data;
}

module.exports.statementsSummary1 = function(results) {
    var output = [];
    output.push(about.general);
    output.push(about.basic_summary);

    return output;
}

module.exports.statementsSummary2 = function(results) {
    var output = [];

    output.push("Your highest overall preference (expressed or wanted) is " + decode[results.basic_highest] + " (" + results[results.basic_highest] + " out of 9)" + ": " + about[results.basic_highest]);
    output.push("Your lowest overall preference (expressed or wanted) is " + decode[results.basic_lowest] + " (" + results[results.basic_lowest] + " out of 9)" + ":  " + about[results.basic_lowest]);
    output.push("Your highest expressed preference is " + decodeExpressed[results.expressed_highest] + " (" + results[results.expressed_highest] + " out of 9)" + ". " + about.highest_expressed);
    return output;
}

module.exports.statementsSummary3 = function(results) {
    var output = [];
  
     output.push(about.sum_inclusion);
    output.push("Your highest overall summary preference (expressed plus wanted) is " + decode[results.sum_highest] + " (" + results.sum_highest_value + " out of 18)" + ": " + about[results.sum_highest]);

    return output;
}

module.exports.statementsDetail = function(results) {
    var output = [];
  
    output.push(about.basic);

    dimensions_basic.forEach(function(dimension) {
        output.push("Your " + decode[dimension] + " score is " + results.summary[dimension].label + " (" + results.summary[dimension].value + " out of 9). " + about[dimension] + "; " + interpretation.basic[results.summary[dimension].index])
    });

    return output;
}

module.exports.statementsTotals1 = function(results) {
    var output = [];

    dimensions_sum.forEach(function(dimension) {

        if (about[dimension]) output.push(about[dimension]);

        output.push("Your " + decode[dimension] + " total (expressed + wanted) score is " + results.summary[dimension].label + " (" + results.summary[dimension].value + " out of 18). " + interpretation[dimension][results.summary[dimension].index])
    });

    return output;
}

module.exports.statementsTotals2 = function(results) {
    var output = [];

    dimensions_totals.forEach(function(dimension) {

        if (about[dimension]) output.push(about[dimension]);

        output.push("Your " + decode[dimension] + " Total score (summed across the three interpersonal preferences) is " + results.summary[dimension].label + " (" + results.summary[dimension].value + "). " + interpretation[dimension][results.summary[dimension].index])
    });

    return output;
}

module.exports.statementsTotals3 = function(results) {
    var output = [];

    if (results.total_expressed > results.total_wanted + 1)
        output.push("Your Expressed Total score is higher than your Wanted Total score.  " + interpretation.comparison_wanted_expressed[0]);
    else if (results.total_expressed < results.total_wanted - 1)
        output.push("Your Wanted Total score is higher than your Expressed Total score.  " + interpretation.comparison_wanted_expressed[2]);
    else
        output.push("Your Wanted Total score is similar to your Expressed Total score.  " + interpretation.comparison_wanted_expressed[1]);

    return output;
}

module.exports.decode = decode;