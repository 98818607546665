
module.exports.questions = [
    "I do not talk on the phone, text, or use social media very often.",
    "I use the same password often.",
    "I avoid doing much work or activity.",
    "My heart rate comes down to normal easily after I’ve experienced stress or anxiety.",
    "I don’t read much, listen to music, or really care for art.",
    "I am often busy with lots of social engagements and I enjoy meeting new people.",
    "I often correct others.",
    "I am detail oriented and finish what I start.",
    "I have a nervous habit that shows often (biting fingernails, fidgeting, avoiding eye contact).",
    "I love to engage my active imagination."
];

module.exports.confirmation = [
    "Disagree",
    "Somewhat Disagree",
    "Neutral",
    "Somewhat Agree",
    "Agree"
];


var image_cards = {
    Jester: 'https://get.karla.ai/KarlaGetsMe/Jester.png?s=2017',
    Rebel: 'https://get.karla.ai/KarlaGetsMe/Rebel.png?s=2017',
    Caregiver: 'https://get.karla.ai/KarlaGetsMe/Caregiver.png?s=2017',
    Sage: 'https://get.karla.ai/KarlaGetsMe/Sage.png?s=2017',
    Creator: 'https://get.karla.ai/KarlaGetsMe/Creator.png?s=2017'
}

var types = {
    Caregiver: {
        low: [
            "You know what you are looking for at this event. You’ll find it.",
            "You know better than to believe everything everyone says.",
            "Sometimes people just need to be left with the effects of their decisions.",
            "You put useful conversations over sticking to the schedule.",
            "You share your opinions with each person you talk to.",
        ],
        high: [
            "You’ll find great resources for your network at this event!",
            "You're bound to be impressed with what the speakers have to offer!",
            "You’ll love how what you learn at events like this will help you help others",
            "You're bound to be impressed with what events like this have to offer!",
            "You probably know a lot about mental health but you don't boast about that."
        ]
    },
    Jester: {
        low: [
            "Enjoy some down time in your hotel room.",
            "You might reach your limit and need a break.",
            "You’re not quiet, you're just taking in everything this event offers.",
            "You have a laid back approach to things.",
            "You take time to slow down even in the middle of event chaos.",
        ],
        high: [
            "Have fun meeting as many new people as you can!",
            "You make friends everywhere you go!",
            "Your outgoing personality makes people feel more comfortable.",
            "You take charge of a situation to move it forward.",
            "You get things done, whether it's work or personal you stay busy.",
        ]
    },
    Sage: {
        low: [
            "There's so much to see here, you might have trouble fitting it all in!",
            "There so much to learn at events!",
            "You might not be interested in everything at this event.",
            "You don't take things too seriously.",
            "All the presentations and companies here are inspiring, but also overwhelming!",
        ],
        high: [
            "You have expertise in this space. Share your wisdom with those interested.",
            "You have a lot to offer graduate students and new professionals.",
            "You like interventions that you know will allow you to provide the best care.",
            "You don't get too excited about new interventions until you know how well it works.",
            "You evaluate everything based on how it will fit into your workflow.",
        ]
    },
    Rebel: {
        low: [
            "In the midst of all these sessions make some time to relax and have fun!",
            "You have mapped out the sessions you will attend and booths you will visit.",
            "You are careful with what you say.",
            "You hold yourself to a high standard.",
            "You talk through things to make sure you fully understand.",
        ],
        high: [
            "Your passion is a great asset to the counseling profession!",
            "You'll be signing up for every chapter, division and committee after hearing what exciting things they are doing!",
            "If you see something that isn't quite up to par, you'll let them know.",
            "Your friends will be impressed with the information and energy you bring back from events like this!",
            "You’re bored if you’ve seen it before. Move on to find something truly new.",
        ]
    },
    Creator: {
        low: [
            "You know therapy requires good processes to be effective.",
            "You look at things in terms of how well their processes work.",
            "You make decisions based on what the data says.",
            "It doesn't matter how attractive something is if it doesn't work properly.",
            "An intervention has to work, otherwise it has no value.",
        ],
        high: [
            "Everything you learn leads you to new ideas to build on what we have to offer our clients and students.",
            "You'll spark new ideas from everything you see at this event!",
            "Just because something is possible, doesn't mean it's a good idea.",
            "The best counseling resources are good content but also well packaged. If it doesn't visually draw you in, you won't use it.",
            "You know that the way people feel about something drives what they do."
        ]
    }
}


module.exports.characteristics = {

    Creator:
    {
        dimension: "Openness",
        low: {
            dimension: "Low Openness",
            adjectives: "practical, conservative, depth of knowledge, efficient, expert",
            label: "Preserver"
        },
        mid: {
            label: "Moderate"
        },
        high: {
            label: "Explorer",
            dimension: "High Openness",
            adjectives: "broad interests, curious, liberal, impractical, likes novelty",
        }
    },

    Sage:
    {
        dimension: "Conscientiousness",
        low: {
            dimension: "Low Conscientiousness",
            adjectives: "spontaneous, fun loving, experimental, unorganized",
            label: "Flexible"
        },
        mid: {
            label: "Balanced"
        },
        high: {
            label: "Focused",
            dimension: "High Conscientiousness",
            adjectives: "dependable, organized, disciplined, cautious, stubborn",
        }
    },

    Jester:
    {
        dimension: "Extraversion",
        low: {
            dimension: "Low Extraversion",
            adjectives: "private, independent, works alone, reserved, hard to read",
            label: "Introvert"
        },
        mid: {
            label: "Ambivert"
        },
        high: {
            label: "Extrovert",
            dimension: "High Extraversion",
            adjectives: "assertive, sociable, warm, optimistic, talkative",
        }
    },

    Caregiver:
    {
        dimension: "Agreeableness",
        low: {
            dimension: "Low Agreeableness",
            adjectives: "skeptical, questioning, tough, aggressive, self interest",
            label: "Challenger"
        },
        mid: {
            label: "Negotiator"
        },
        high: {
            label: "Adapter",
            dimension: "High Agreeableness",
            adjectives: "trusting, humble, altruistic, team player, conflict averse, frank",
        }
    },

    Rebel:
    {
        dimension: "Neuroticism",
        low: {
            dimension: "Low Neuroticism",
            adjectives: "secure, unflappable, rational, unresponsive, guilt free",
            label: "Resilient"
        },
        mid: {
            label: "Responsive"
        },
        high: {
            label: "Reactive",
            dimension: "High Neuroticism",
            adjectives: "excitable, worrying, reactive, highstrung, alert",
        }
    },
}

module.exports.types = types;

/*
JESTER
Extraversion
1R, 6   0R  5

CAREGIVER
Agreeableness
2, 7R    1 6R

SAGE
Conscientiousness
3R, 8    2R  7

REBEL
Neuroticism
4R, 9     3R  8

CREATOR
Openness
5R, 10    4R  9
*/

function _reverse(score) {
    return 6 - score;
}

function _normalize(score) {
    return Number(((score - 2) / 8).toFixed(1));
}

// This function generates random integer between two numbers low (inclusive) and high (exclusive) ([low, high))
function _randomInt(low, high) {
    return Math.floor(Math.random() * (high - low) + low);
}

module.exports.analyze = function (a) {
    var results = [
        { name: 'Jester', value: _normalize(_reverse(a[0].value) + a[5].value), alternative: 'Extraversion', id: "1" },
        { name: 'Caregiver', value: _normalize(a[1].value + _reverse(a[6].value)), alternative: 'Agreeableness', id: "2" },
        { name: 'Sage', value: _normalize(_reverse(a[2].value) + a[7].value), alternative: 'Conscientiousness', id: "3" },
        { name: 'Rebel', value: _normalize(_reverse(a[3].value) + a[8].value), alternative: 'Neuroticism', id: "4" },
        { name: 'Creator', value: _normalize(_reverse(a[4].value) + a[9].value), alternative: 'Openness', id: "5" }
    ];

    // sort by value
    results.sort(function (a, b) {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

    var hightype = results[4].name;
    var lowtype = results[0].name;

    var combinedtype = results[0].id + results[4].id;


    var highstatements = [];
    var lowstatements = [];
    for (var i = 0; i < 5; i++) {
        highstatements.push(types[hightype].high[i]);
        lowstatements.push(types[lowtype].low[i]);
    }

    var highindex = _randomInt(0, 5);
    var lowindex = _randomInt(0, 5);

    var highselected = highstatements[highindex];
    var lowselected = lowstatements[lowindex];

    highstatements.splice(highindex, 1);
    lowstatements.splice(lowindex, 1);

    highindex = _randomInt(0, 4);
    lowindex = _randomInt(0, 4);

    var highselected2 = highstatements[highindex];
    var lowselected2 = lowstatements[lowindex];

    var blob = { high: hightype, low: lowtype, combinedtype: combinedtype, scores: results, statements: [highselected, lowselected], nextdaystatements: [highselected2, lowselected2] };
    blob.mediaUrl = image_cards[hightype];

    var msg = "Great to meet you! Enjoy the rest of the event. \nI extend Human Compassion through Artificial Intelligence\nhttps://synchealth.co/get-karla\n\nResults\nHigh: " + hightype + "\nLow: " + lowtype + "\n\n" + highselected + "\n\n" + lowselected;

    var msg2 = "It was great meeting you at the event last week! We hope you enjoyed learning a little about yourself and can see the value of this insight and targeted interaction with the population you serve. Visit our website to learn more and register to try Karla through our early access waiting list.\n\n" + highselected2 + "\n\n" + lowselected2 + "\n\nhttp://synchealth.co/get-karla?src=1611&t=" + combinedtype;

    blob.msg = msg;
    blob.msg2 = msg2;

    return blob;
}