module.exports = {
  "BROWSER": true,
  "AWS_IDENTITY_POOL_ID": "us-east-1:dca9390b-cfff-4b2f-a337-68934f2181bb",
  "AWS_REGION": "us-east-1",
  "FIREBASE_API_KEY": "AIzaSyAGmnVekwmkCLvdaGdWm3Ju4XXHk7g6S9w",
  "FIREBASE_AUTH_DOMAIN": "karla-labs.firebaseapp.com",
  "FIREBASE_DATABASE_URL": "https://karla-labs.firebaseio.com",
  "FIREBASE_STORAGE_BUCKET": "karla-labs.appspot.com",
  "FIREBASE_MESSAGING_SENDER_ID": "11650375685",
  "FIREBASE_ROOT": "/careers",
  "SYNCHEALTH_SMS_URL": "https://karla-dev.azurewebsites.net/api/HttpOnboardSMS?code=2akFp5urrDrOvMH7gueQTUclymaHuvRIf2IdzNU01T4zKtVUaeQsmQ==",
  "SYNCHEALTH_SLACK_URL": "https://karla-dev.azurewebsites.net/api/HttpOnboardSMS?code=2akFp5urrDrOvMH7gueQTUclymaHuvRIf2IdzNU01T4zKtVUaeQsmQ==",
  "SYNCHEALTH_ATS_PRIVATE_URL": "https://karla-dev.azurewebsites.net/api/HttpRecruitee?code=C0PtMrrhtcZgnqSEFUMv0L4WiI8fRYvNhYm5HeAnj2NGnFcLmqcZMQ=="
}