

exports.YesNoCard = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: questionNumber ? "Question #" + (questionNumber + 1) : null,
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "no",
            "text": "No",
            "type": "button",
            "value": "NoIntent"
          },
          {
            "name": "yes",
            "text": "Yes",
            "type": "button",
            "value": "YesIntent"
          }
        ]
      }
    ]
  }
}

exports.FrequencyCard = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: "Question #" + (questionNumber + 1),
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "not at all",
            "text": "Not at all",
            "type": "button",
            "value": "NotAtAllIntent"
          },
          {
            "name": "several days",
            "text": "Several days",
            "type": "button",
            "value": "SeveralDaysIntent"
          },
          {
            "name": "more than half the days",
            "text": "More than half the days",
            "type": "button",
            "value": "MoreThanHalfOfDaysIntent"
          },
          {
            "name": "nearly every day",
            "text": "Nearly every day",
            "type": "button",
            "value": "NearlyEveryDayIntent"
          }
        ]
      }
    ]
  }
}

exports.FrequencyCardA = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: "Question #" + (questionNumber + 1),
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "never",
            "text": "Never",
            "type": "button",
            "value": "NeverIntent"
          },
          {
            "name": "rarely",
            "text": "Rarely",
            "type": "button",
            "value": "RarelyIntent"
          },
          {
            "name": "sometimes",
            "text": "Sometimes",
            "type": "button",
            "value": "SometimesIntent"
          },
          {
            "name": "often",
            "text": "Often",
            "type": "button",
            "value": "OftenIntent"
          }, {
            "name": "always",
            "text": "Always",
            "type": "button",
            "value": "AlwaysIntent"
          }
        ]
      }
    ]
  }
}

exports.LikertCard = function (question, questionNumber) {
  return {
    type: "Standard",
    text: question,
    title: "Question #" + (questionNumber + 1),
    "attachments": [
      {
        "type": "multi-choice",
        actions: [
          {
            "name": "disagree",
            "text": "Disagree",
            "type": "button",
            "value": "StronglyDisagreeIntent"
          },
          {
            "name": "somewhatdisagree",
            "text": "Somewhat Disagree",
            "type": "button",
            "value": "SlightlyDisagreeIntent"
          },
          {
            "name": "neutral",
            "text": "Neutral",
            "type": "button",
            "value": "NeutralIntent"
          },
          {
            "name": "somewhatagree",
            "text": "Somewhat Agree",
            "type": "button",
            "value": "SlightlyAgreeIntent"
          }, {
            "name": "agree",
            "text": "Agree",
            "type": "button",
            "value": "StronglyAgreeIntent"
          }
        ]
      }
    ]
  }
}

exports.decodeIntent = {
  "ReadyIntent": "Ready",
  "StartSurveyIntent": "Start",
  "DontKnowIntent": "Don't Know",
  "StartOverIntent": "Start Over",
  "RepeatIntent": "Repeat",
  "YesIntent": "Yes",
  "NoIntent": "No",
  "StopIntent": "Stop",
  "NotAtAllIntent": "Not at all",
  "SeveralDaysIntent": "Several days",
  "MoreThanHalfOfDaysIntent": "More than half the days",
  "NearlyEveryDayIntent": "Nearly every day",
  "NeverIntent": "Never",
  "RarelyIntent": "Rarely",
  "SometimesIntent": "Sometimes",
  "OftenIntent": "Often",
  "AlwaysIntent": "Always"
}
